import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Img from "gatsby-image"
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS} from "@contentful/rich-text-types";
// import LeadForm from "./leadform";
// import MobileLeadingForm from "./mobileleadform";
import Blog from "./landingblog";

const Articles = (props) => {
    const data = useStaticQuery(graphql`
        query {
          allContentfulArticles {
            edges {
              node {
                body {
                  body
                }
                id
                title
                intro
                updatedAt
                createdAt
                cover {
                  localFile {
                    url
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `);
    const options = {
        renderNode: {
            // eslint-disable-next-line react/display-name
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const image = node.data.target.fields.file['en-US']
                const width = image.details.image.width
                return <Img style={{marginBottom: '28px'}} width={image.details.image.width} fluid={{
                    aspectRatio: width / image.details.image.height,
                    src: image.url + '?w=630&q=80',
                    srcSet: ` 
              ${image.url}?w=${width / 4}&&q=80 ${width / 4}w,
              ${image.url}?w=${width / 2}&&q=80 ${width / 2}w,
              ${image.url}?w=${width}&&q=80 ${width}w,
              ${image.url}?w=${width * 1.5}&&q=80 ${width * 1.5}w,
              ${image.url}?w=1000&&q=80 1000w,
          `,
                    sizes: '(max-width: 630px) 100vw, 630px'
                }}/>
            }
        }
    }
    const articles = data.allContentfulArticles.edges;
    const currentArticle = articles.filter(({node: a}) => a.id === props.pageContext.article.id)[0].node
    currentArticle['type'] = 'article';
    return (
        <Layout>
            <SEO schemaData={currentArticle} title={currentArticle.title} description={currentArticle.intro} cover={currentArticle.cover.localFile.url}/>
            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}

            <div className={'hidden xxl:block purple-rotated-div'}/>
            <div className={'hidden xxl:block gray-rotated-div'}/>
            <div className={'container mx-auto mb-10'}>
                <div className={'flex '}>
                    <div className="container mx-auto px-4">
                        <h1 className=" max-w-3xl mx-auto text-3xl font-bold tracking-wide leading-snug mt-4 lg:mt-4">{currentArticle.title}</h1>
                        <p className=" max-w-3xl mx-auto mb-8">{currentArticle.intro}</p>
                        <Img fluid={currentArticle.cover.localFile.childImageSharp.fluid} className="max-w-3xl mb-8 mx-auto "/>
                        <div className="mx-auto container text-navy-700">
                            <div className="article mx-auto max-w-3xl leading-relaxed tracking-wide">{documentToReactComponents(JSON.parse(currentArticle.body.body), options)}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={''}>
                <Blog/>
            </div>
        </Layout>
    )
};

export default Articles